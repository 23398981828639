import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import BasicModal from "components/Modals/BasicModal";

const NewUtente = () => {
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [ruolo, setRuolo] = useState("");
  const [codiceFiscale, setCodiceFiscale] = useState("");
  const [email, setEmail] = useState("");
  const [ruoloDescrizione, setRuoloDescrizione] = useState("");
  const [TabContacTypes, setTabContacTypes] = useState("");
  const [ruoli, setRuoli] = useState([]);
  const [cellulare, setCellulare] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const fetchWithToken = async (url, callback) => {
    const accessToken = localStorage.getItem("access_token");

    if (!accessToken) {
      console.error("Access Token non trovato nel localStorage");
      return;
    }

    const authHeader = `Bearer ${accessToken}`;

    try {
      const response = await axios.get(url, {
        responseType: "json",
        headers: { Authorization: authHeader },
      });

      if (response.status === 200) {
        callback(response.data.rows);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchWithToken(
      `${process.env.REACT_APP_BASE_URL}/api/tab-roles`,
      (data) => {
        setRuoli(data);
      }
    );
  }, []);

  useEffect(() => {
    fetchWithToken(
      `${process.env.REACT_APP_BASE_URL}/api/tab-contact-types`,
      (data) => {
        setTabContacTypes(data);
      }
    );
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      name: nome,
      surname: cognome,
      roles: [{ id: ruolo }],
      username: email,
      fiscal_code: codiceFiscale,
      contacts: [
        { type_id: TabContacTypes[0].id, value: email },
        { type_id: TabContacTypes[2].id, value: cellulare },
      ],
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/user`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setIsModalOpen(true);
          setIsDisabled(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const toggleNewCustomer = () => {
    setIsDisabled(false);
    setNome("");
    setCognome("");
    setRuolo("");
    setCodiceFiscale("");
    setEmail("");
    setCellulare("");
  };


  return (
    <>
      <BasicModal
        subject={"Utente aggiunto con successo"}
        isActive={isModalOpen}
        onConfirm={() => setIsModalOpen(!isModalOpen)}
      />
      <Row>
        <Col className="order-xl-2 mb-4 mb-xl-0" xl="4">
          <Card className="card-profile shadow">
            <CardBody className="pt-0 pt-md-4">
              <Row>
                <div className="col">
                  <div className="card-profile-stats d-flex justify-content-center">
                    <div>
                      <img
                        style={{ maxWidth: "200px" }}
                        alt="..."
                        className="rounded-circle"
                        src={require("../../assets/img/theme/default-user-icon.jpg")}
                      />
                    </div>
                  </div>
                </div>
              </Row>
              <div className="text-center">
                <h1>
                  {nome} {cognome}
                </h1>
                <div className="h3 font-weight-300">
                  <i className="ni location_pin mr-2" />
                  {ruoloDescrizione}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col className="order-xl-1" xl="8">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0 Headercard">
              <Row className="align-items-center">
                <Col
                  xs="12"
                  className="d-flex flex-row align-items-center"
                  style={{ justifyContent: "space-between" }}
                >
                  <h3 className="mb-0">Utente</h3>
                  <Button
                    color="primary"
                    onClick={toggleNewCustomer}
                    disabled={!isDisabled}
                  >
                    Aggiungi nuovo Utente
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <h6 className="heading-small text-muted mb-4">Informazioni</h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-nome"
                        >
                          Nome
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-nome"
                          placeholder="Mario"
                          type="text"
                          onChange={(e) => setNome(e.target.value)}
                          value={nome}
                          required="Required"
                          disabled={isDisabled}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-cognome"
                        >
                          Cognome
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-cognome"
                          placeholder="Rossi"
                          type="text"
                          onChange={(e) => setCognome(e.target.value)}
                          value={cognome}
                          required="Required"
                          disabled={isDisabled}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-ruolo"
                        >
                          Ruolo
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="ruolo"
                          placeholder="PM"
                          type="select"
                          name="ruolo"
                          onChange={(e) => {
                            const selectedRoleId = e.target.value;
                            setRuolo(selectedRoleId);
                            const selectedRole = ruoli.find((role) => role.id === selectedRoleId);
                            if (selectedRole) {
                              setRuoloDescrizione(selectedRole.description);
                            }
                          }}
                          value={ruolo}
                          required="Required"
                          disabled={isDisabled}
                        >
                          <option value="">Seleziona un ruolo</option>
                          {ruoli.map((role) => (
                            <option
                              key={role.id}
                              value={role.id}
                              name={role.id}
                            >
                              {role.description}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-codiceFiscale"
                        >
                          Codice Fiscale
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="codiceFiscale"
                          placeholder="codice fiscale"
                          type="text"
                          name="codiceSDI"
                          onChange={(e) => setCodiceFiscale(e.target.value)}
                          value={codiceFiscale}
                          required="Required"
                          disabled={isDisabled}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-codiceSDI"
                        >
                          Email
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="Email"
                          placeholder="mariorossi@schema31.it"
                          type="text"
                          name="Email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          required="Required"
                          disabled={isDisabled}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-Cellulare"
                        >
                          Cellulare
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="Cellulare"
                          placeholder="3336123455"
                          type="text"
                          name="codiceSDI"
                          onChange={(e) => setCellulare(e.target.value)}
                          value={cellulare}
                          required="Required"
                          disabled={isDisabled}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <FormGroup check row>
                      <Col>
                        <Button
                          color="success"
                          type="submit"
                          disabled={isDisabled}
                        >
                          Salva
                        </Button>
                      </Col>
                    </FormGroup>
                  </Row>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default NewUtente;
