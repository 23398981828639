import {
  Container,
  Row,
  Col
} from "reactstrap";

import { useState } from "react";
import DynamicHeader from "components/DynamicHeader/DynamicHeader";
import HeaderItem from "components/DynamicHeader/HeaderWidgets/HeaderItem";
import ElencoUtenti from "components/Tables/ElencoUtenti";
import NewUtente from "./NewUtente";

const Utenti = () => {
  const [selectedWidget, setSelectedWidget] = useState(1);
  const [counter, setCounter] = useState(0);

  return (
    <>
      <DynamicHeader>
        
        <Row className="mx-xl-5" style={{justifyContent: "center"}}>
          <Col lg="6" xl="3" className="my-2" onClick={() => setSelectedWidget(1)}>
            <HeaderItem
              isActive={selectedWidget === 1 ? true : false}
              title={"Elenco Utenti"}
              color={"primary"}
              icon={<i className="fa-solid fa-users-rectangle"></i>}
            />
          </Col>
          <Col lg="6" xl="3" className="my-2" onClick={() => setSelectedWidget(2)}>
            <HeaderItem
              isActive={selectedWidget === 2 ? true : false}
              title={"Aggiungi Utente"}
              color={"blue"}
              icon={<i className="fa-solid fa-user-plus"></i>}
            />
          </Col>
        </Row>
      </DynamicHeader>
      <Container className="mt--7 bg-gradient-info-edit" fluid>
        <Row>
          <Col>
          <div className="d-flex justify-content-center">
            {selectedWidget === 1 && <ElencoUtenti />}
            {selectedWidget === 2 && <NewUtente />}
          </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Utenti;
