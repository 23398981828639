export function formatItalianDate(inputDate) {
  // Converti la data in formato AAAA-MM-GG
  const parts = inputDate.split(' ')[0].split('/');
  const datePart = parts.join('-');
  const timePart = inputDate.split(' ')[1];
  const formattedDate = new Date(`${datePart}T${timePart}`);
  

  // Formatta la data come GG/MM/AAAA HH:mm:ss
  const year = formattedDate.getFullYear();
  const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
  const day = String(formattedDate.getDate()).padStart(2, '0');
  const hours = String(formattedDate.getHours()).padStart(2, '0');
  const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
  const seconds = String(formattedDate.getSeconds()).padStart(2, '0');

  // Restituisci la data nel formato italiano con ore, minuti e secondi
  return `${day}/${month}/${year}  ${hours}:${minutes}:${seconds}`;
}


export function formatNumber(inputNumber) {
  // Formatta il numero con 2 decimali e separatore delle migliaia
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return inputNumber.toLocaleString('it-IT', options);
}