import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import axios from "axios";

const ProtocollazioneDeleteModal = ({ subject, isActive, onConfirm, id }) => {
    const [modal, setModal] = useState(isActive);
    const [scartoNote, setScartoNote] = useState('');

    useEffect(() => {
        setModal(isActive);
        setScartoNote(''); // Resetta la nota quando la modale viene aperta
    }, [isActive]);

    const toggle = () => {
        setModal(!modal);
        if (onConfirm) {
            // Invia la nota solo quando la modale viene chiusa (conferma scarto)
            onConfirm();
        }
    };

      
        const handleConfirmScarto = async () => {
            try {
                const accessToken = localStorage.getItem("access_token");
                if (!accessToken) {
                  console.error("Access Token non trovato nel localStorage");
                  // Gestisci come desideri se l'access token non è disponibile
                  return;
                }
                const authHeader = `Bearer ${accessToken}`;
                const apiOptions = { headers: { Authorization: authHeader } };
        
                const response = await axios.put(
                    `${process.env.REACT_APP_BASE_URL}/api/inbox/${id}`,
                    {
                        wasted_note: scartoNote,
                    },
                    apiOptions
                );
        
                if (response.status === 200) {
                    console.log("Dati salvati con successo");
                }
            } catch (error) {
                console.error("Errore durante il salvataggio dei dati:", error);
            }
        
            toggle();
        };
        

    return (
        
            <div>
                <Modal centered isOpen={modal} toggle={toggle} backdrop={true} keyboard={true}>
                    <ModalHeader>Conferma Scarto</ModalHeader>
                    <ModalBody>
                        <span className='d-flex align-items-center'>
                            {subject}
                        </span>
                        <Input
                            type='textarea'
                            placeholder='Inserisci la nota di scarto...'
                            value={scartoNote}
                            onChange={(e) => setScartoNote(e.target.value)}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button className='mx-auto w-25 m-2' color='danger' onClick={handleConfirmScarto}>
                            Scarta
                        </Button>
                        <Button className='mx-auto w-25 m-2' color='secondary' onClick={toggle}>
                            Annulla
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        
    );
};

export default ProtocollazioneDeleteModal;

