import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const DeleteModal = ({ subject, isActive, onConfirm, URL }) => {
  const [modal, setModal] = useState(isActive);
  const navigate = useNavigate();

  useEffect(() => {
    // Aggiorna lo stato modal quando cambia il valore di isActive
    setModal(isActive);
  }, [isActive]);

  
  const toggle = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        console.error("Access Token non trovato nel localStorage");
        // Gestisci come desideri se l'access token non è disponibile
        return;
      }
  
      const authHeader = `Bearer ${accessToken}`;
      const apiOptions = { headers: { Authorization: authHeader } };
  
      setModal(!modal);
  
      if (onConfirm) {
        onConfirm();
  
        const response = await axios.delete(URL, apiOptions);
  
        if (response.status === 204) {
          // Aggiorna lo stato o esegui altre azioni in base alle tue esigenze
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  

  const toggle2 = () => {
    setModal(!modal);
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <>
      <div>
        <Modal centered isOpen={modal} toggle={toggle2}>
          <ModalBody>
            <span className="d-flex align-items-center">
              <i className="ni ni-fat-delete mr-2 display-4" />
              {subject}
            </span>
          </ModalBody>
          <ModalFooter>
            <Button
              className="mx-auto w-25 m-2"
              color="danger"
              onClick={toggle}
            >
              Cancella
            </Button>
            <Button
              className="mx-auto w-25 m-2"
              color="primary"
              onClick={toggle2}
            >
              Annulla
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default DeleteModal;
