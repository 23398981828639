
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import LoadingPage from "components/LoadingPage"; // Importa la tua pagina di caricamento qui
import { AuthProvider } from "react-oidc-context";

const root = ReactDOM.createRoot(document.getElementById("root"));

const oidcConfig = {
  authority: "https://is.schema31.it/oauth2/oidcdiscovery",
  client_id: "l0P5iJK5YGr9AhUlya_Xf0vzXOYa",
  redirect_uri: `${process.env.REACT_APP_BASE_URL_REDIRECT}/loading`, // Modifica il percorso in uno generico
  scope: "openid profile email",
  metadata: {
    authorization_endpoint: "https://is.schema31.it/oauth2/authorize",
    end_session_endpoint: "https://is.schema31.it/oidc/logout",
    token_endpoint: "https://is.schema31.it/oauth2/token",
    jwks_uri: "https://is.schema31.it/oauth2/jwks",
  },
};

root.render(
  <AuthProvider {...oidcConfig}>
    <BrowserRouter>
      <Routes>
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="/loading" element={<LoadingPage />} /> {/* Pagina di caricamento generica */}
        <Route path="*" element={<Navigate to="auth" replace />} />
      </Routes>
    </BrowserRouter>
  </AuthProvider>
);
