import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from "react-router-dom";

const BasicModal = ({ subject, isActive, onConfirm }) => {
    const [modal, setModal] = useState(isActive);

    const navigate = useNavigate();

    useEffect(() => {
        setModal(isActive);
    }, [isActive]);

    const toggle = () => {
        setModal(!modal);
        if (onConfirm) {
            onConfirm();
            navigate("/admin/inbox");
        }
    };

    return (
        <>
            <div>
                <Modal centered isOpen={modal} toggle={toggle}>
                    <ModalBody>
                        <span className='d-flex align-items-center'>
                            <i className='ni ni-check-bold mr-2 display-4' />
                            {subject}
                        </span>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='mx-auto w-25 m-2' color='primary' onClick={toggle}>
                            Ok
                        </Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
        </>
    );
};

export default BasicModal;
