import Utenti from "views/examples/Utenti.js";
import Inbox from "views/examples/Inbox.js";
import Protocollate from "views/examples/Protocollate.js";

const getStoredUserRole = () => {
  return JSON.parse(localStorage.getItem("user")) || { rows: { roles: [{ code: "" }] } };
};

const getRoutes = (roleCode) => {
  const routesAdmin = [
    {
      path: "/inbox",
      name: "Inbox",
      icon: "fa-regular fa-envelope text-primary",
      component: <Inbox />,
      layout: "/admin",
    },
    {
      path: "/protocollati",
      name: "Protocollati",
      icon: "fa-solid fa-folder text-primary",
      component: <Protocollate />,
      layout: "/admin",
    },
    {
      path: "/utenti",
      name: " Utenti",
      icon: "ni ni-circle-08 text-primary",
      component: <Utenti />,
      layout: "/admin",
    },
  ];

  const routesProtocollatore = [
    {
      path: "/inbox",
      name: "Inbox",
      icon: "fa-regular fa-envelope text-primary",
      component: <Inbox />,
      layout: "/admin",
    },
    {
      path: "/protocollati",
      name: "Protocollati",
      icon: "fa-solid fa-folder text-primary",
      component: <Protocollate />,
      layout: "/admin",
    },
  ];

  const routesConsultazione = [
    {
      path: "/protocollati",
      name: "Protocollati",
      icon: "fa-solid fa-folder text-primary",
      component: <Protocollate />,
      layout: "/admin",
    },
  ];

  switch (roleCode) {
    case "ADMIN":
      return routesAdmin;
    case "ARCHIVIER":
      return routesProtocollatore;
    case "USER":
      return routesConsultazione;
    default:
      return [];
  }
};

const updateRoutes = () => {
  const userRole = getStoredUserRole();
  const roleCode = userRole.rows.roles[0].code;
  return getRoutes(roleCode);
};

let routes = getRoutes(getStoredUserRole().rows.roles[0].code);

window.addEventListener("storage", (event) => {
  if (event.key === "user") {
    const newRoleCode = getStoredUserRole().rows.roles[0].code;
    if (newRoleCode !== routes[0].rows.roles[0].code) {
      routes = getRoutes(newRoleCode);
    }
  }
});

export default routes;


