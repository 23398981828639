import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Table,
  Button,
  Input,
} from "reactstrap";
import ReactPaginate from "react-paginate";
import InboxCard from "components/Cards/InboxCard";
import CustomFilter from "./CustomFilterTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ElencoProtocollate = () => {
  const [customersList, setCustomersList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [inboxStatus, setInboxStatus] = useState("2"); 
  const [filters, setFilters] = useState({
    periodoProtocollazione: null,
    periodoProtocollazioneFine: null,
    recipient_company_id: "",
    sender_email: "",
    tag: "",
  });

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterName]: value }));
  };

  const handleFilter = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        console.error("Access Token non trovato nel localStorage");
        // Gestisci come desideri se l'access token non è disponibile
        return;
      }

      const authHeader = `Bearer ${accessToken}`;
      const apiOptions = { headers: { Authorization: authHeader } };

      const dateStart =
        filters.periodoProtocollazione == null
          ? "1927-11-14 00:00:00"
          : formatDateTime(filters.periodoProtocollazione);
      const dateEnd =
        filters.periodoProtocollazioneFine == null
          ? "2080-11-14 23:59:59"
          : formatDateTime(filters.periodoProtocollazioneFine);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/inboxes/find`,
        {
          selector: {
            "inbox.inbox_status_id": "2",
            registers: {
              [`registers.created_at BETWEEN '${dateStart}' AND '${dateEnd}'`]: "",
              [`registers.sender_email LIKE '%${filters.sender_email}%'`]: "",
              [`registers.tag LIKE '%${filters.tag}%'`]: "",
              [`registers.recipient_company_id LIKE '%${filters.recipient_company_id}%'`]: "",
            },
          },
          order: [
            {
              "inbox.id": "DESC",
            },
          ],
          limit: 10,
        },
        apiOptions
      );

      if (response.status === 200) {
        setCustomersList(response.data.rows);
      }
    } catch (error) {
      // handle error
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        console.error("Access Token non trovato nel localStorage");
        return;
      }

      const authHeader = `Bearer ${accessToken}`;
      const apiOptions = { headers: { Authorization: authHeader } };

      const dateStart =
        filters.periodoProtocollazione == null
          ? "1927-11-14 00:00:00"
          : formatDateTime(filters.periodoProtocollazione);
      const dateEnd =
        filters.periodoProtocollazioneFine == null
          ? "2080-11-14 23:59:59"
          : formatDateTime(filters.periodoProtocollazioneFine);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/inboxes/find`,
        {
          selector: {
            "inbox.inbox_status_id": "2",
            registers: {
              [`registers.created_at BETWEEN '${dateStart}' AND '${dateEnd}'`]: "",
              [`registers.sender_email LIKE '%${filters.sender_email}%'`]: "",
              [`registers.tag LIKE '%${filters.tag}%'`]: "",
              [`registers.recipient_company_id LIKE '%${filters.recipient_company_id}%'`]: "",
            },
          },
          order: [
            {
              "inbox.id": "DESC",
            },
          ],
          limit: 10,
        },
        apiOptions
      );

      if (response.status === 200) {
        setCustomersList(response.data.rows);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const formatDateTime = (date) => {
    const pad = (n) => (n < 10 ? `0${n}` : n);
    const formattedDate = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
      date.getDate()
    )} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
    return formattedDate;
  };

  const handleResetFilter = () => {
    setFilters({
      periodoProtocollazione: null,
      periodoProtocollazioneFine: null,
      recipient_company_id: "",
      sender_email: "",
      tag: "",
    });
    fetchData();
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access Token non trovato nel localStorage");
          return;
        }

        const authHeader = `Bearer ${accessToken}`;
        const apiOptions = { headers: { Authorization: authHeader } };

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/companies`,
          apiOptions
        );

        if (response.status === 200) {
          setCompanies(response.data.rows);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    fetchData();
  }, [filters, currentPage]);

  const perPage = 10;
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const offset = currentPage * perPage;
  const paginatedCustomers = customersList.slice(offset, offset + perPage);

  return (
    <>
      {!isLoading && (
        <Card className="shadow fade-in">
          <div className="filter-section">
            <CustomFilter
              label="Mittente"
              value={filters.sender_email}
              onChange={(value) => handleFilterChange("sender_email", value)}
            />
            <DatePicker
              className="filter-date"
              placeholderText="data iniziale"
              selected={filters.periodoProtocollazione}
              onChange={(date) =>
                handleFilterChange("periodoProtocollazione", date)
              }
            />
            <DatePicker
              className="filter-date"
              placeholderText="data fine"
              selected={filters.periodoProtocollazioneFine}
              onChange={(date) =>
                handleFilterChange("periodoProtocollazioneFine", date)
              }
            />
            <CustomFilter
              label="Chiave di ricerca"
              value={filters.tag}
              onChange={(value) => handleFilterChange("tag", value)}
            />
            <Input
              className="filter-select"
              type="select"
              name="recipient_company_id"
              id="recipient_company_id"
              placeholder="Azienda destinataria"
              onChange={(event) =>
                handleFilterChange("recipient_company_id", event.target.value)
              }
              value={filters.recipient_company_id}
            >
              <option value="">Seleziona un'azienda</option>
              {companies.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </Input>
            <Button
              className="filter-button filter-button-apply"
              onClick={handleFilter}
            >
              Applica
            </Button>
            <Button
              className="filter-button filter-button-reset"
              onClick={handleResetFilter}
            >
              Resetta
            </Button>
          </div>
          <CardHeader className="border-0 Headercard">
            <h3 className="mb-0">Elenco Protocollate</h3>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Mittente </th>
                <th scope="col">Data ora di ricezione</th>
                <th scope="col">Oggetto</th>
                <th scope="col">Corpo della mail</th>
              </tr>
            </thead>
            <tbody>
              {paginatedCustomers.map((client) => (
                <InboxCard
                  key={client.id}
                  id={client.id}
                  name={client.register.sender_email ? client.register.sender_email : client.sender_email}
                  data={client.created_at.date}
                  soggetto={client.register.subject ? client.register.subject : client.subject}
                  corpoDellaMail={client.body}
                  inboxStatus={inboxStatus}
                  allegati={client.codiceContabile}
                />
              ))}
            </tbody>
          </Table>
          <CardFooter className="py-4 bottomcard">
            <nav aria-label="...">
              <ReactPaginate
                previousLabel={
                  <PaginationItem>
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                }
                nextLabel={
                  <PaginationItem>
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                }
                pageCount={Math.ceil(customersList.length / perPage)}
                onPageChange={handlePageClick}
                containerClassName={
                  "pagination justify-content-end mb-0 align-items-center"
                }
                pageClassName={"mx-4"}
                activeClassName={"font-weight-bolder "}
              />
            </nav>
          </CardFooter>
        </Card>
      )}
    </>
  );
};

export default ElencoProtocollate;
