import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import PostalMime from 'postal-mime';
import axios from 'axios';  // Importa Axios
import { useParams } from "react-router-dom";

const EmailParser = () => {
  const [emailInfo, setEmailInfo] = useState(null);
  const [renderedHtml, setRenderedHtml] = useState('');

  const { id } = useParams();

  useEffect(() => {
    const fetchEmailContent = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access Token non trovato nel localStorage");
          // Gestisci come desideri se l'access token non è disponibile
          return;
        }
  
        const authHeader = `Bearer ${accessToken}`;
        const apiOptions = { headers: { Authorization: authHeader } };
  
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/inbox/${id}/raw/body`, apiOptions);
  
        const emailText = response.data;
        parseEmailContent(emailText);
      } catch (error) {
        console.error('Errore durante il recupero del contenuto dell\'email', error);
      }
    };
  
    fetchEmailContent();
  }, [id]);
  

  const parseEmailContent = async (emailText) => {
    try {
      const parser = new PostalMime();
      const parsedEmail = await parser.parse(emailText);
      setEmailInfo(parsedEmail);

      // Estrai il contenuto HTML dalla mail analizzata
      const htmlContent = parsedEmail.html;
      setRenderedHtml(htmlContent);
    } catch (error) {
      console.error('Errore durante il parsing dell\'email', error);
      // Gestisci l'errore o fornisce un feedback all'utente
    }
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col sm="12" >
          {renderedHtml && (
            <div className="mt-4" style={{ display: 'flex', minWidth: '100%', alignItems: "center", justifyContent: "center" }}>
              <div dangerouslySetInnerHTML={{ __html: renderedHtml }} />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default EmailParser;
