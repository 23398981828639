import React from 'react'
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Button,
    UncontrolledTooltip,
} from "reactstrap";
import { Link } from 'react-router-dom';
import { formatItalianDate } from 'utils/libs/functions';

function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength - 3) + "...";
    }
  }

const InboxCard = ({id,name,soggetto,data,corpoDellaMail,allegati, inboxStatus, wasted_note}) => {
    return (
        <>
            <tr>
                <th scope='row'>
                <p>{id}</p>
                </th>
                <th scope="row">
                    <Media className="align-items-center">
                        <Media>
                            <Link to={`/admin/dettaglio-inbox/${id}`} className="mb-0 text-sm">
                            {truncateText(name, 40)}
                            </Link>
                        </Media>
                    </Media>
                </th>
                    <th scope='row'>
                     {formatItalianDate(data)}
                    </th>
                    {inboxStatus === "1" && <th scope='row'>
                     {truncateText(soggetto, 40)}
                    </th>}
                   {inboxStatus === "1" &&  <th scope='row'>
                     {truncateText(corpoDellaMail, 40)}
                    </th>}
                    {inboxStatus === "2" && <th scope='row'>
                     {truncateText(soggetto, 40)}
                    </th>}
                   {inboxStatus === "2" &&  <th scope='row'>
                     {truncateText(corpoDellaMail, 40)}
                    </th>}
                    {inboxStatus === "3" && <th scope='row'>
                     {truncateText(soggetto, 40)}
                    </th>}
                    {inboxStatus === "3" &&   <th scope='row'>
                     {truncateText(wasted_note, 70)}
                    </th>}
                  { /* <th scope='row'>
                        {allegati}
                    </th>
                    <th scope='row'>
                    <Button className="btn-sm btn-primary" color="primary">Protocolla</Button>
                </th>
                <th scope='row'>
                    <Button className="btn-sm btn-danger" color="danger">Scarta</Button>
                </th>
                */}
            </tr>
        </>
    )
}

export default InboxCard