import {
  Container,
  Row,
  Col
} from "reactstrap";

import { useState } from "react";
import DynamicHeader from "components/DynamicHeader/DynamicHeader";
import ElencoProtocollate from "components/Tables/ElencoProtocollate";

const Protocollate = () => {
  const [selectedWidget, setSelectedWidget] = useState(1);
  const [counter, setCounter] = useState(0);

  return (
    <>
      <DynamicHeader alternative=" e visualizzazione dei file protocollati.">
      </DynamicHeader>
      <Container className="mt--7 bg-gradient-info-edit" fluid>
        <Row>
          <Col>
          <div className="d-flex justify-content-center">
            {selectedWidget === 1 && <ElencoProtocollate />}
          </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Protocollate;
