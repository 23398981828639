/*!

=========================================================
* Argon Dashboard React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { useState } from "react";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";



const AdminNavbar = (props) => {

  const auth = useAuth()
  const user = JSON.parse(localStorage.getItem("user"))
  
  const [userName, setUserName] = useState("ciao");

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid style={{justifyContent: "center"}}>
          <Nav className="align-items-center d-none d-md-flex button-personalized" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center profile-home">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={require("../../assets/img/theme/default-user-icon.jpg")}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="spacing">{user ? `${user.rows.name} ${user.rows.surname}` : ""}</span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow extra-dropdown-menu-edit card" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0 icon-menu-profile">Area personale</h6>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()} className="menu-profile fix-background">
                  <i className="ni ni-user-run icon-menu-profile" />
                  <span onClick={() => auth.signoutRedirect() }>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
