/*!

=========================================================
* Argon Dashboard React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

// core components

import routes from "routes.js";
import { useAuth } from "react-oidc-context";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";

const Auth = (props) => {
  const location = useLocation();
  const auth = useAuth();




  switch (auth.activeNavigator) {
    case "signinSilent":
        return <div>Signing you in...</div>;
    case "signoutRedirect":
        return <div>Signing you out...</div>;
}

if (auth.isLoading) {
    return <div>Loading...</div>;
}

if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
}

  return (
    <>
      <div className="main-content">
        <AuthNavbar />
        <div className="header bg-gradient-info-edit py-7 py-lg-8" style={{minHeight: "100vh"}}>
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">Benvenuto!</h1>
                  <p className="text-lead text-light">
                    Questo è il nuovo sistema di protocollazione di Schema31
                  </p>
                  <Button onClick={() => void auth.signinRedirect()}>Log in</Button>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
        
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Auth;
