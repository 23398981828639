import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LoadingPage = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  // Customize the loading message based on the OIDC state
  const [loadingMessage, setLoadingMessage] = useState("Loading...");

  const checkAuth = async () => {
    const { user, isLoading, isAuthenticated } = auth;

    if (!isLoading && isAuthenticated && user && user.access_token) {
      const accessToken = user.access_token;
      console.log("Access Token:", accessToken);

      // Chiamata API con Axios
      try {
        const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/auth`;
        const response = await axios.post(apiUrl, {
          access_token: accessToken,
        });

        // Gestisci la risposta della chiamata API
        console.log("API Post Success:", response.data);

        // Salva il token nel localStorage
        localStorage.setItem("access_token", accessToken);

        // Salva la risposta della chiamata API nel localStorage (se necessario)
        localStorage.setItem("user", JSON.stringify(response.data));

        // Reindirizza l'utente a "/admin/inbox"
        navigate("/admin/inbox");
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Aggiorna il messaggio di caricamento per indicare l'errore 401
          setLoadingMessage("Utenza non presente a Sistema");
        } else {
          console.error("API Post Error:", error);
          setLoadingMessage("Errore durante l'autenticazione");
        }
      }
    } else {
      console.error("Access Token non disponibile");
      setLoadingMessage("Utenza non presente a Sistema");
    }
  };

  useEffect(() => {
    checkAuth();
  }, [auth, navigate]);

  return (
    <>
      <div className="main-content">
        <div
          className="header bg-gradient-info-edit py-7 py-lg-8"
          style={{ minHeight: "100vh" }}
        >
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">Loading Page</h1>
                  <p className="text-lead text-light">{loadingMessage}</p>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          {/* You can add additional content or customize the layout here */}
        </Container>
      </div>
    </>
  );
};

export default LoadingPage;
