import React from "react";
import { Button, InputGroup, Input } from "reactstrap";

const CustomFilter = ({ label, value, onChange }) => {
  return (
    <div className="my-2 filter-input">
      <InputGroup>
        <Input
          style={{"borderRadius": "21px"}}
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={label}
        />
      </InputGroup>
    </div>
  );
};

export default CustomFilter;
