import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import BasicModal from "components/Modals/BasicModal";
import HeaderNewUtente from "components/Headers/HeaderNewUtente.js";
import DeletedModal from "components/Modals/DeletedModal";

const DetailUser = () => {
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editFormAbled, setEditFormAbled] = useState(true);
  const { id } = useParams();
  const [nome, setNome] = useState("");
  const [ruoli, setRuoli] = useState([]);
  const [cognome, setCognome] = useState("");
  const [email, setEmail] = useState("");
  const [cellulare, setCellulare] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [codiceFiscale, setCodiceFiscale] = useState("");

  const fetchData = async (url, callback) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        console.error("Access Token non trovato nel localStorage");
        return;
      }

      const authHeader = `Bearer ${accessToken}`;
      const apiOptions = { headers: { Authorization: authHeader } };

      const response = await axios.get(url, apiOptions);

      if (response.status === 200) {
        callback(response.data.rows);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData(`${process.env.REACT_APP_BASE_URL}/api/tab-roles`, (data) => {
      setRuoli(data);
    });
  }, []);

  useEffect(() => {
    fetchData(`${process.env.REACT_APP_BASE_URL}/api/user/${id}`, (data) => {
      setNome(data.name);
      setCognome(data.surname);
      setSelectedRole(
        ruoli.find((role) => role.id === data.roles[0].id)?.id || ""
      );
      setCodiceFiscale(data.fiscal_code);
      setEmail(
        data.contacts.find((contact) => contact.contact_type_id === "1")?.value ||
          ""
      );
      setCellulare(
        data.contacts.find((contact) => contact.contact_type_id === "3")?.value ||
          ""
      );
    });
  }, [id, ruoli]);

  const handleDelete = () => {
    setIsModalOpenDelete(true);
  };

  const handleUpgrade = async (url, callback) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        console.error("Access Token non trovato nel localStorage");
        return;
      }
  
      const authHeader = `Bearer ${accessToken}`;
      const apiOptions = { headers: { Authorization: authHeader } };
  
      const formData = {
        name: nome,
        surname: cognome,
        roles: [{ id: selectedRole }],
        contacts:[
          {
              type_id: 1,
              value: email
          },          {
            type_id: 3,
            value: cellulare
        }],
        username: email,
        fiscal_code: codiceFiscale,
      };
  
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/user/${id}`, formData, apiOptions);
  
      if (response.status === 201) {
        setIsModalOpen(true);
        setEditFormAbled(!editFormAbled);
      } else {
        console.error(`Errore durante la richiesta: ${response.status}`);
      }
    } catch (error) {
      console.error("Errore durante la richiesta:", error);
    }
  };
  

  const handleEdit = () => {
    setEditFormAbled(!editFormAbled);
  };

  return (
    <>
          <BasicModal
          subject={"Utente modificato con successo"}
          isActive={isModalOpen}
          onConfirm={() => setIsModalOpen(!isModalOpen)}
        />
        <DeletedModal
          subject={"Vuoi rimuovere realmente questo Utente ?"}
          isActive={isModalOpenDelete}
          onConfirm={() => setIsModalOpenDelete(!isModalOpenDelete)}
          URL={`${process.env.REACT_APP_BASE_URL}/api/user/${id}`}
        />
    <HeaderNewUtente />
      {/* Page content */}
      <Container className="mt--7 bg-gradient-info-edit" fluid>
        <Row>
          <Col className="order-xl-2 mb-4 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center">
                      <div>
                        <img
                          style={{ maxWidth: "200px" }}
                          alt="..."
                          className="rounded-circle"
                          src={require("../../assets/img/theme/default-user-icon.jpg")}
                        />
                      </div>
                    </div>
                  </div>
                </Row>
                <div className="text-center">
                  <h1>
                    {nome} {cognome}
                  </h1>
                  <div className="h3 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0 Headercard">
              </CardHeader>
              <CardBody>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault(e);
                  }}
                >
                  <h6 className="heading-small text-muted mb-4">
                    Informazioni
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-nome"
                          >
                            Nome
                          </label>
                          <Input
                          disabled={editFormAbled}
                            className="form-control-alternative"
                            id="input-nome"
                            placeholder="Mario"
                            type="text"
                            onChange={(e) => setNome(e.target.value)}
                            value={nome}
                            required="Required"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cognome"
                          >
                            Cognome
                          </label>
                          <Input
                          disabled={editFormAbled}
                            className="form-control-alternative"
                            id="input-cognome"
                            placeholder="Rossi"
                            type="text"
                            onChange={(e) => setCognome(e.target.value)}
                            value={cognome}
                            required="Required"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                      <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-regione"
                          >
                            Ruolo
                          </label>
                          <Input
                          disabled={editFormAbled}
                            className="form-control-alternative"
                            id="ruolo"
                            placeholder="ruolo"
                            type="select"
                            name="ruolo"
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              const selectedRoleObj = ruoli.find(
                                (role) => role.id == selectedValue                             
                              )
                              setSelectedRole(selectedRoleObj.id
                              );
                            }}
                            value={selectedRole}
                            required="Required"
                            defaultValue={selectedRole}
                          >
                            {ruoli.map((role) => (
                              <option key={role.id} value={role.id}>
                                {role.description}
                              </option>
                            ))
                            }
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-codiceFiscale"
                          >
                            Codice Fiscale
                          </label>
                          <Input
                          disabled={editFormAbled}
                            className="form-control-alternative"
                            id="codiceFiscale"
                            placeholder="codice fiscale"
                            type="text"
                            name="codiceSDI"
                            onChange={(e) => setCodiceFiscale(e.target.value)}
                            value={codiceFiscale}
                            required="Required"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-codiceSDI"
                          >
                            Email
                          </label>
                          <Input
                          disabled={editFormAbled}
                            className="form-control-alternative"
                            id="Email"
                            placeholder="mariorossi@schema31.it"
                            type="text"
                            name="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required="Required"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-Cellulare"
                          >
                            Cellulare
                          </label>
                          <Input
                          disabled={editFormAbled}
                            className="form-control-alternative"
                            id="Cellulare"
                            placeholder="3336123455"
                            type="text"
                            name="codiceSDI"
                            onChange={(e) => setCellulare(e.target.value)}
                            value={cellulare}
                            required="Required"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                       
                      <FormGroup check row>
                        <Col>
                          <Button
                            color="primary"
                            type="button"
                            onClick={handleEdit}
                          >
                            Modifica
                          </Button>
                        </Col>
                      </FormGroup>
                      
                      <FormGroup check row>
                        <Col>
                          <Button
                            disabled={editFormAbled}
                            color="success"
                            type="button"
                            onClick={handleUpgrade}
                          >
                            Salva
                          </Button>
                        </Col>
                      </FormGroup>
                      
                      <FormGroup check row>
                        <Col>
                          <Button
                            color="danger"
                            type="button"
                            onClick={handleDelete}
                          >
                            Cancella Utente
                          </Button>
                        </Col>
                      </FormGroup>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DetailUser;
