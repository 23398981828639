import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import { useParams } from "react-router-dom";
import ProtocollazioneModal from "components/Modals/ProtocollazioneModal";

import HeaderNewCliente from "components/Headers/HeaderNewCliente.js";
import React, { useState, useEffect } from "react";
import axios from "axios";
import EmailParser from "utils/EmailParser";
import { formatItalianDate } from "utils/libs/functions";
import ProtocollazioneDeleteModal from "components/Modals/ProtocollazioneDeleteModal";

const DetailInbox = () => {
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [mittente, setMittente] = useState("");
  const [oggetto, setOggetto] = useState("");
  const [corpoMail, setCorpoMail] = useState("");
  const [status, setStatus] = useState("");
  const [dataRicezione, setDataRicezione] = useState("");
  const [editFormAbled, setEditFormAbled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [numeroDiProtocollo, setNumeroDiProtocollo] = useState([]);
  const [recipient_company_id, setRecipient_company_id] = useState([]);
  const [original_location_id, setOriginal_location_id] = useState([]);
  const [owner_type_id, setOwner_type_id] = useState([]);
  const [tag, setTag] = useState([]);
  const [originalLocationTable, setOriginalLocationTable] = useState([]);
  const [ownerTypeTable, setOwnerTypeTable] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [newSubject, setNewSubject] = useState("");

  const { id } = useParams();

  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength - 3) + "...";
    }
  }

  const user = JSON.parse(localStorage.getItem("user"));
  console.log(user.rows.name, "DUCATI");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access Token non trovato nel localStorage");
          // Gestisci come desideri se l'access token non è disponibile
          return;
        }

        const authHeader = `Bearer ${accessToken}`;
        const apiOptions = { headers: { Authorization: authHeader } };

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/inbox/${id}`,
          { responseType: "json", ...apiOptions }
        );

        if (response.status === 200) {
          setStatus(response.data.rows.inbox_status_id);
          setMittente(response.data.rows.sender_email);
          setOggetto(response.data.rows.subject);
          setCorpoMail(response.data.rows.body);
          setDataRicezione(
            formatItalianDate(response.data.rows.created_at.date)
          );
          setAttachments(response.data.rows.attachments);

          if (response.data.rows.register) {
            setNumeroDiProtocollo(response.data.rows.register.register);
            setTag(response.data.rows.register.tag);
            setRecipient_company_id(
              response.data.rows.register.recipient_company_id
            );
            setOriginal_location_id(
              response.data.rows.register.original_location_id
            );
            setOwner_type_id(response.data.rows.register.owner_type_id);
            setNewSubject(response.data.rows.register.subject);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access Token non trovato nel localStorage");
          // Gestisci come desideri se l'access token non è disponibile
          return;
        }

        const authHeader = `Bearer ${accessToken}`;
        const apiOptions = {
          responseType: "json",
          headers: { Authorization: authHeader },
        };

        const [ownerTypeResponse, originalLocationResponse, companiesResponse] =
          await Promise.all([
            axios.get(
              `${process.env.REACT_APP_BASE_URL}/api/tab-owner-types`,
              apiOptions
            ),
            axios.get(
              `${process.env.REACT_APP_BASE_URL}/api/tab-original-locations`,
              apiOptions
            ),
            axios.get(
              `${process.env.REACT_APP_BASE_URL}/api/companies`,
              apiOptions
            ),
          ]);

        setOwnerTypeTable(ownerTypeResponse.data.rows);
        setOriginalLocationTable(originalLocationResponse.data.rows);
        setCompanies(companiesResponse.data.rows);
      } catch (error) {
        console.error("Errore durante il recupero dei dati:", error);
      }
    };

    fetchData();
  }, []);

  const handleProtocollaClick = () => {
    setIsModalOpen(true);
  };

  const handleDeleteClick = () => {
    setIsModalOpenDelete(true);
  };

  const handleSaveModal = (formData) => {
    // Implementa la logica per inviare l'API POST con i dati
    console.log("Dati dalla modale:", formData);
    // Chiudi la modale dopo il salvataggio
    setIsModalOpen(false);
  };

  const handleDownloadAttachment = (attachmentId) => {
    // Logica per scaricare l'allegato con l'ID specificato
    window.open(
      `${process.env.REACT_APP_BASE_URL}/api/inbox/${id}/attachment/${attachmentId}`,
      "_blank"
    );
  };

  return (
    <>
      <HeaderNewCliente />
      <ProtocollazioneDeleteModal
        subject={"Vuoi realmente cancellare questo documento ?"}
        isActive={isModalOpenDelete}
        onConfirm={() => setIsModalOpenDelete(false)}
        id={id}
      />
      {/* Page content */}
      <Container className="mt--7 bg-gradient-info-edit" fluid>
        <Row style={{ justifyContent: "center" }}>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow fade-in">
              <CardHeader className="bg-white border-0 Headercard">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Dettaglio Documento</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form
                  onSubmit={(e) => {
                    "";
                  }}
                >
                  <h6 className="heading-small text-muted mb-4">
                    Informazioni
                  </h6>
                  <ProtocollazioneModal
                    isActive={isModalOpen}
                    onSave={handleSaveModal}
                    onCancel={() => setIsModalOpen(false)}
                    mittente={mittente}
                    oggetto={oggetto}
                    id_inbox={id}
                  />

                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-mittente"
                          >
                            Mittente
                          </label>
                          <Input
                            disabled={editFormAbled}
                            className="form-control-alternative"
                            id="input-mittente"
                            placeholder="mittente"
                            type="text"
                            onChange={(e) => setMittente(e.target.value)}
                            value={mittente}
                            required="Required"
                            defaultValue={
                              newSubject != "" ? newSubject : mittente
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-data-ricezione"
                          >
                            Data Ricezione
                          </label>
                          <Input
                            disabled={editFormAbled}
                            className="form-control-alternative"
                            id="input-data-ricezione"
                            placeholder="Data ricezione"
                            type="text"
                            onChange={(e) => setDataRicezione(e.target.value)}
                            value={dataRicezione}
                            required="Required"
                            defaultValue={dataRicezione}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="Oggetto"
                          >
                            Oggetto
                          </label>
                          <Input
                            disabled={editFormAbled}
                            className="form-control-alternative"
                            id="Oggetto"
                            placeholder="Oggetto"
                            type="text"
                            name="Oggetto"
                            onChange={(e) => setOggetto(e.target.value)}
                            value={newSubject != "" ? newSubject : oggetto}
                            required="Required"
                            defaultValue={
                              newSubject != "" ? newSubject : oggetto
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="corpoMail"
                          >
                            Corpo della mail
                          </label>
                          <Input
                            disabled={editFormAbled}
                            className="form-control-alternative"
                            id="corpoMail"
                            placeholder="corpoMail"
                            type="text"
                            name="corpoMail"
                            onChange={(e) => setCorpoMail(e.target.value)}
                            value={corpoMail}
                            required="Required"
                            defaultValue={corpoMail}
                          />
                        </FormGroup>
                      </Col>
                      {status === "2" && (
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="corpoMail"
                            >
                              Numero di Protocollo
                            </label>
                            <Input
                              disabled={editFormAbled}
                              className="form-control-alternative"
                              id="Numero di Protocollo"
                              placeholder="numero di protocollo"
                              type="text"
                              name="numeroDiProtocollo"
                              onChange={(e) =>
                                setNumeroDiProtocollo(e.target.value)
                              }
                              value={numeroDiProtocollo}
                              required="Required"
                              defaultValue={numeroDiProtocollo}
                            />
                          </FormGroup>
                        </Col>
                      )}
                      {status === "2" && (
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="corpoMail"
                            >
                              Tag
                            </label>
                            <Input
                              disabled={editFormAbled}
                              className="form-control-alternative"
                              id="Tag"
                              placeholder="tag"
                              type="text"
                              name="tag"
                              onChange={(e) => setTag(e.target.value)}
                              value={tag}
                              required="Required"
                              defaultValue={tag}
                            />
                          </FormGroup>
                        </Col>
                      )}
                      {status === "2" && (
                        <Col lg="6">
                          <FormGroup>
                            <label for="aziendaDestinataria">
                              Azienda Destinataria
                            </label>
                            <Input
                              disabled={editFormAbled}
                              type="select"
                              name="recipient_company_id"
                              id="aziendaDestinataria"
                              onChange={""}
                              value={recipient_company_id}
                            >
                              <option value="">Seleziona una azienda</option>
                              {companies.map((company) => (
                                <option key={company.id} value={company.id}>
                                  {company.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      )}{" "}
                      {status === "2" && (
                        <Col lg="6">
                          <FormGroup>
                            <label for="posizioneOriginale">
                              Posizione dell'Originale
                            </label>

                            <Input
                              disabled={editFormAbled}
                              type="select"
                              name="original_location_id"
                              id="posizioneOriginale"
                              onChange={""}
                              value={original_location_id}
                            >
                              <option value="">Seleziona una posizione</option>
                              {originalLocationTable.map((originalLocation) => (
                                <option
                                  key={originalLocation.id}
                                  value={originalLocation.id}
                                >
                                  {originalLocation.description}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      )}
                      {status === "2" && (
                        <Col lg="6">
                          <FormGroup>
                            <label for="tipoProtocollo">Tipo Protocollo</label>
                            <Input
                              disabled={editFormAbled}
                              type="select"
                              name="owner_type_id"
                              id="tipoProtocollo"
                              onChange={""}
                              value={owner_type_id}
                            >
                              <option value="">Seleziona un tipo</option>
                              {ownerTypeTable.map((ownerType) => (
                                <option key={ownerType.id} value={ownerType.id}>
                                  {ownerType.description}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {attachments.map((attachment) => (
                        <Col key={attachment.id} lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor={`attachment-${attachment.id}`}
                            ></label>
                            <Button
                              color="primary"
                              type="button"
                              onClick={() =>
                                handleDownloadAttachment(attachment.id)
                              }
                            >
                              Scarica allegato{" "}
                              {truncateText(attachment.name, 30)}
                            </Button>
                          </FormGroup>
                        </Col>
                      ))}
                    </Row>
                    {status === "1" && (
                      <Row>
                        <FormGroup check row>
                          <Col>
                            <Button
                              color="primary"
                              type="button"
                              onClick={handleProtocollaClick}
                            >
                              Protocolla
                            </Button>
                          </Col>
                        </FormGroup>
                        <FormGroup check row>
                          <Col>
                            <Button
                              color="danger"
                              type="button"
                              onClick={handleDeleteClick}
                            >
                              Scarta
                            </Button>
                          </Col>
                        </FormGroup>
                      </Row>
                    )}
                    {status === "3" && (
                      <Row>
                        <FormGroup check row>
                          <Col>
                            <Button
                              color="primary"
                              type="button"
                              onClick={handleProtocollaClick}
                            >
                              Protocolla
                            </Button>
                          </Col>
                        </FormGroup>
                      </Row>
                    )}
                  </div>
                  <EmailParser id={id} />
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DetailInbox;
