import {
    Card,
    CardHeader,
    CardFooter,
    PaginationItem,
    PaginationLink,
    Table,
} from "reactstrap";

import axios from "axios";
import { useState, useEffect } from "react";
import UserCard from "components/Cards/UserCard";
import ReactPaginate from 'react-paginate';

const ElencoUtenti = () => {
    const [userList, setUserList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const accessToken = localStorage.getItem("access_token");
            if (!accessToken) {
              console.error("Access Token non trovato nel localStorage");
              // Gestisci come desideri se l'access token non è disponibile
              return;
            }
      
            const authHeader = `Bearer ${accessToken}`;
            const apiOptions = { headers: { Authorization: authHeader } };
      
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/users`, apiOptions);
      
            // handle success
            setUserList(response.data.rows.filter((item) => item.active === "1"));
            setIsLoading(false); // Imposta isLoading su false dopo aver caricato i dati
          } catch (error) {
            // handle error
            console.log(error);
          }
        };
      
        fetchData();
      }, []);
      

    const perPage = 10;

    const handlePageClick = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    const offset = currentPage * perPage;
    const paginatedUser = userList.slice(offset, offset + perPage);
    return ( <>
        { !isLoading ? 
        <Card className="shadow fade-in">
            <CardHeader className="border-0 Headercard">
                <h3 className="mb-0">Elenco Utenti</h3>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Nome e Cognome</th>
                        <th scope="col">Email</th>
                        <th scope="col">Cellulare</th>
                        <th scope="col">Ruolo</th>
                        
                        <th scope="col" />
                    </tr>
                </thead>
                <tbody>
                    {paginatedUser.map((client) => (
                        <UserCard
                            key={client.id}
                            id={client.id}
                            name={client.name}
                            cognome={client.surname}
                            email={client.contacts.find(contact => contact.contact_type_id === "1")?.value}
                            cellulare={client.contacts.find(contact => contact.contact_type_id === "3")?.value}
                            ruolo={client.roles[0].description}
                        />))}

                </tbody>
            </Table>
            <CardFooter className="py-4 bottomcard">
                <nav aria-label="...">
                    <ReactPaginate
                        previousLabel={<PaginationItem>
                            <PaginationLink
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                tabIndex="-1"
                            >
                                <i className="fas fa-angle-left" />
                                <span className="sr-only">Previous</span>
                            </PaginationLink>
                        </PaginationItem>}
                        nextLabel={<PaginationItem>
                            <PaginationLink
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                            >
                                <i className="fas fa-angle-right" />
                                <span className="sr-only">Next</span>
                            </PaginationLink>
                        </PaginationItem>}
                        pageCount={Math.ceil(userList.length / perPage)}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end mb-0 align-items-center"}
                        pageClassName={"mx-4"}
                        activeClassName={"font-weight-bolder "}
                    />
                </nav>
            </CardFooter>
        </Card> : ""             } </>
    ) 
}

export default ElencoUtenti