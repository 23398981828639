import {
  Container,
  Row,
  Col
} from "reactstrap";

import React, { useContext } from "react";
import { useAuth } from "react-oidc-context";

import { useState } from "react";
import DynamicHeader from "components/DynamicHeader/DynamicHeader";
import HeaderItem from "components/DynamicHeader/HeaderWidgets/HeaderItem";
import ElencoInbox from "components/Tables/ElencoInbox";

const Inbox = () => {
  const [selectedWidget, setSelectedWidget] = useState(1);
  const [counter, setCounter] = useState(0);
  const { user, isLoading, isAuthenticated, error } = useAuth();
  // Controlla se user contiene il token
  if (!isLoading && isAuthenticated && user && user.access_token) {
    const accessToken = user.access_token;
    // Ora puoi utilizzare accessToken come desiderato
    console.log("Access Token:", accessToken);
  } else {
    // L'utente potrebbe non essere autenticato o il token potrebbe non essere disponibile
    console.error("Access Token non disponibile");
  }

  return (
    <>
      <DynamicHeader alternative=" delle mail in entrata.">
      </DynamicHeader>
      <Container className="mt--7 bg-gradient-info-edit" fluid>
        <Row>
          <Col>
          <div className="d-flex justify-content-center">
            {selectedWidget === 1 && <ElencoInbox />}
          </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Inbox;
