import React from "react";
import {
  Media,
} from "reactstrap";
import { Link } from "react-router-dom";
import ruoli from "utils/enum/RoleList";

  // Cerca il ruolo corrispondente nell'array 'ruoli'

const UserCard = ({ id, name, cognome, email, cellulare, ruolo }) => {
  return (
    <>
      <tr>
        <th scope="row">
          <p>{id}</p>
        </th>
        <th scope="row">
          <Media className="align-items-center">
            <Media>
              <Link
                to={`/admin/dettaglio-utente/${id}`}
                className="mb-0 text-sm"
              >
                {name} {cognome}
              </Link>
            </Media>
          </Media>
        </th>
        <th scope="row">{email}</th>
        <th scope="row">{cellulare}</th>
        <th scope="row">{ruolo}
        </th>
      </tr>
    </>
  );
};

export default UserCard;
