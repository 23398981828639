import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import InboxCard from "components/Cards/InboxCard";
import ReactPaginate from "react-paginate";

const ElencoInbox = () => {
  const [customersList, setCustomersList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [inboxStatus, setInboxStatus] = useState("1"); // Default value

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access Token non trovato nel localStorage");
          return;
        }

        const authHeader = `Bearer ${accessToken}`;
        const apiOptions = { headers: { Authorization: authHeader } };

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/inboxes`,
          apiOptions
        );

        setCustomersList(
          response.data.rows.filter(
            (item) => item.inbox_status_id === inboxStatus
          )
        );

        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [inboxStatus]);

  const perPage = 10;

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleSelectChange = (e) => {
    setIsLoading(true)
    const newInboxStatus = e.target.value;
    setInboxStatus(newInboxStatus);
  };

  const offset = currentPage * perPage;
  const paginatedCustomers = customersList.slice(offset, offset + perPage);
  return (
    <>
      {!isLoading ? (
        <Card className="shadow fade-in">
          <CardHeader
            className="border-0 Headercard"
            style={{ display: "flex", alignItems: "center" }}
          >
            <h3 className="mb-0" style={{ paddingRight: "5px" }}>
              Elenco
            </h3>
            <select
              value={inboxStatus}
              onChange={handleSelectChange}
              
              style={{
                borderRadius: "21px",
                cursor: "pointer",
                paddingLeft: "10px",
                width: "200px",
                height: "calc(1.5em + 1.25rem + 2px)",
                fontWeight: "400",
                lineheight: "1.5",
                color: "#8898aa",
                backgroundColor: "#fff",
                backgroundClip: "padding-box",
                border: "1px solid #cad1d7",
              }}
            >
              <option value="1">Inbox</option>
              <option value="3">Cestino</option>
            </select>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Mittente </th>
                <th scope="col">Data ora di ricezione</th>
                {inboxStatus === "1" && <th scope="col">Oggetto</th>}
                {inboxStatus === "1" && <th scope="col">Corpo della mail</th>}
                {inboxStatus === "2" && <th scope="col">Oggetto</th>}
                {inboxStatus === "2" && <th scope="col">Corpo della mail</th>}
                {inboxStatus === "3" && <th scope="col">Oggetto</th>}
                {inboxStatus === "3" && <th scope="col">Nota di Scarto</th>}
              </tr>
            </thead>
            <tbody>
              {paginatedCustomers.map((client) => (
                <InboxCard
                  key={client.id}
                  id={client.id}
                  name={client.sender_email}
                  data={client.created_at.date}
                  soggetto={client.subject}
                  corpoDellaMail={client.body}
                  allegati={client.codiceContabile}
                  inboxStatus={inboxStatus}
                  wasted_note={client.wasted_note? client.wasted_note : ""}
                />
              ))}
            </tbody>
          </Table>
          <CardFooter className="py-4 bottomcard">
            <nav aria-label="...">
              <ReactPaginate
                previousLabel={
                  <PaginationItem>
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                }
                nextLabel={
                  <PaginationItem>
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                }
                pageCount={Math.ceil(customersList.length / perPage)}
                onPageChange={handlePageClick}
                containerClassName={
                  "pagination justify-content-end mb-0 align-items-center"
                }
                pageClassName={"mx-4"}
                activeClassName={"font-weight-bolder "}
              />
            </nav>
          </CardFooter>
        </Card>
      ) : (
        ""
      )}{" "}
    </>
  );
};

export default ElencoInbox;
