import React from 'react'
import { Card, CardBody, Row, Col } from 'reactstrap'

const HeaderItem = ({title, counter, icon, color, isActive}) => {
    return (
        <div className='mb-3' style={{cursor: "pointer"}}>
            <Card className={`h-100 pl-2 py-3 ${isActive ? `card-selected` : null}`}>
                <Row className='d-flex justify-content-between'>
                    <div className="col top-bar-button">
                        <span className="h2 font-weight-bold mb-0">
                            {title}
                        </span>
                    </div>
                    <div>
                        {counter ?
                            <div className={`icon icon-shape text-white rounded-circle shadow bg-${color}`}>
                                {counter}
                            </div>
                            :
                            <div className={`icon icon-shape text-white rounded-circle shadow bg-${color}`}>
                                {icon}
                            </div>
                        }
                    </div>
                </Row>
            </Card>
        </div>
    )
}

export default HeaderItem