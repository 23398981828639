import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import axios from "axios";
import { WithOutContext as ReactTags } from "react-tag-input";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import BasicModal from "./BasicModal";

const ProtocollazioneModal = ({
  isActive,
  onSave,
  onCancel,
  mittente,
  oggetto,
  id_inbox,
}) => {
  const [formData, setFormData] = useState({
    subject: oggetto,
    sender_email: mittente,
    register_type_id: 1,
    owner_type_id: "",
    original_location_id: "",
    recipient_company_id: "",
    tag: "",
    inbox_id: id_inbox,
  });
  const [modal, setModal] = useState(isActive);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ownerType, setOwnerType] = useState([]);
  const [originalLocationTable, setOriginalLocationTable] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [tags, setTags] = useState([]);
  useEffect(() => {
    setModal(isActive);
  }, [isActive]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access Token non trovato nel localStorage");
          // Gestisci come desideri se l'access token non è disponibile
          return;
        }

        const authHeader = `Bearer ${accessToken}`;
        const apiOptions = {
          responseType: "json",
          headers: { Authorization: authHeader },
        };

        const [ownerTypeResponse, originalLocationResponse, companiesResponse] =
          await Promise.all([
            axios.get(
              `${process.env.REACT_APP_BASE_URL}/api/tab-owner-types`,
              apiOptions
            ),
            axios.get(
              `${process.env.REACT_APP_BASE_URL}/api/tab-original-locations`,
              apiOptions
            ),
            axios.get(
              `${process.env.REACT_APP_BASE_URL}/api/companies`,
              apiOptions
            ),
          ]);

        setOwnerType(ownerTypeResponse.data.rows);
        setOriginalLocationTable(originalLocationResponse.data.rows);
        setCompanies(companiesResponse.data.rows);
      } catch (error) {
        console.error("Errore durante il recupero dei dati:", error);
      }
    };

    fetchData();
  }, []);

  const toggle = () => {
    setModal(!modal);
    if (onCancel) {
      onCancel();
    }
  };

  const handleTagsChange = (newTags) => {
    setTags(newTags);
    setFormData({
      ...formData,
      tag: newTags.map((tag) => tag.text).join(", "),
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    const integerFields = [
      "inbox_id",
      "original_location_id",
      "register_type_id",
      "recipient_company_id",
      "owner_type_id",
    ];

    const formDataWithIntegers = { ...formData,};

    integerFields.forEach((field) => {
      if (formDataWithIntegers[field]) {
        formDataWithIntegers[field] = parseInt(formDataWithIntegers[field], 10);
      }
    });

    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        console.error("Access Token non trovato nel localStorage");
        // Gestisci come desideri se l'access token non è disponibile
        return;
      }

      const authHeader = `Bearer ${accessToken}`;
      const apiOptions = { headers: { Authorization: authHeader } };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/register`,
        formDataWithIntegers,
        apiOptions
      );

      if (response.status === 200) {
        console.log("Dati salvati con successo");
        if (onSave) {
          onSave(formData);
        }
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Errore durante il salvataggio dei dati:", error);
    }
  };

  return (
    <div>
      <BasicModal
        subject={"Documento Protocollato"}
        isActive={isModalOpen}
        onConfirm={() => setIsModalOpen(false)} // Imposta isModalOpen a false per chiudere il BasicModal
      />
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader>Compila i campi</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="subject">Breve Descrizione</Label>
              <Input
                type="text"
                name="subject"
                id="breveDescrizione"
                onChange={handleInputChange}
                defaultValue={oggetto}
                
              />
            </FormGroup>

            <FormGroup>
              <Label for="tipoProtocollo">Tipo Protocollo</Label>
              <Input
                type="select"
                name="owner_type_id"
                id="tipoProtocollo"
                onChange={handleInputChange}
                value={formData.owner_type_id}
              >
                <option value="">Seleziona un tipo</option>
                {ownerType.map((ownerType) => (
                  <option key={ownerType.id} value={ownerType.id}>
                    {ownerType.description}
                  </option>
                ))}
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="posizioneOriginale">Posizione dell'Originale</Label>

              <Input
                type="select"
                name="original_location_id"
                id="posizioneOriginale"
                onChange={handleInputChange}
                value={formData.original_location_id}
              >
                <option value="">Seleziona una posizione</option>
                {originalLocationTable.map((originalLocation) => (
                  <option key={originalLocation.id} value={originalLocation.id}>
                    {originalLocation.description}
                  </option>
                ))}
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="aziendaDestinataria">Azienda Destinataria</Label>
              <Input
                type="select"
                name="recipient_company_id"
                id="aziendaDestinataria"
                onChange={handleInputChange}
                value={formData.recipient_company_id}
              >
                <option value="">Seleziona una azienda</option>
                {companies.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="sender_email">Mittente</Label>
              <Input
                type="text"
                name="sender_email"
                id="sender_email"
                onChange={handleInputChange}
                defaultValue={mittente}
                
              />
            </FormGroup>
            <FormGroup>
              <Label for="chiaviRicerca">Chiavi di Ricerca</Label>
              <DndProvider backend={HTML5Backend}>
                <ReactTags
                  tags={tags}
                  suggestions={[]} // Puoi fornire suggerimenti se necessario
                  handleDelete={(index) =>
                    handleTagsChange(tags.filter((tag, i) => i !== index))
                  }
                  handleAddition={(tag) => handleTagsChange([...tags, tag])}
                  placeholder="Aggiungi una chiave di ricerca"
                  classNames={{
                    tags: "form-control", // Aggiungi la classe form-control per allineare il campo
                    tagInputField: "col-12", // Aggiungi la classe col-12 per mantenere la larghezza uniforme
                    remove: "custom-remove",
                    tagInput: "custom-tag-input",
                  }}
                />
              </DndProvider>
            </FormGroup>

            <FormGroup>
              <Label for="azioneDaFare">Eventuale Azione da Fare</Label>
              <Input
                type="text"
                name="azioneDaFare"
                id="azioneDaFare"
                onChange={handleInputChange}
                value={formData.azioneDaFare}
                readOnly
              />
            </FormGroup>

            <FormGroup>
              <Label for="scadenzaAttivita">Scadenza Attività</Label>
              <Input
                type="date"
                name="scadenzaAttivita"
                id="scadenzaAttivita"
                onChange={handleInputChange}
                value={formData.scadenzaAttivita}
                readOnly
              />
            </FormGroup>

            <FormGroup>
              <Label for="chiusaIl">Chiusa il</Label>
              <Input
                type="date"
                name="chiusaIl"
                id="chiusaIl"
                onChange={handleInputChange}
                value={formData.chiusaIl}
                readOnly
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            Salva
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Annulla
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ProtocollazioneModal;
